<template>
  <div class="user-tab-overview">
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>

      <!-- actions -->
      <!-- <v-card-text class="d-flex align-center flex-wrap pb-0">
        <table-search
          v-model="options.q"
          dense
          outlined
          hide-details
          full-width
        />
      </v-card-text> -->

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="items"
        :options="options"
        :loading="loading"
        disable-sort
        disable-pagination
        hide-default-footer
        :search.sync="options.q"
      >
        <!-- name -->
        <template #[`item.displayName`]="{item}">
          <router-link
            v-if="item.displayName"
            :to="{ name : 'item-view', params : { id : item.id } }"
            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            {{ item.displayName }}
          </router-link>
          <span v-else>-</span>
        </template>

        <template #[`item.slideImageUrl`]="{item}">
          <a
            v-if="item.slideImageUrl"
            :href="item.slideImageUrl"
            target="_blank"
            class="text--break"
          >
            {{ item.slideImageUrl | textEllipsis(120) }}
          </a>
          <span
            v-else
            class="text--secondary"
          >-</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name:'item-view', params:{ id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Item</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiFilePdf, mdiEyeOutline } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { ref, computed, onMounted, onUnmounted } from '@vue/composition-api'
import useItemList from './useItemList'

export default {
  components: {},
  props: {
    campaignId: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      defualt: () => [],
    },
  },
  setup(props) {
    const loading = ref(false)
    const totalItemListTable = computed(() => props.items.length || 0)

    const {
      itemListTable,
      tableColumns,
      options,

      init,
      destroy,
    } = useItemList(props.campaignId)

    onMounted(() => {
      init()
    })

    onUnmounted(() => {
      destroy()
    })

    return {
      icons: {
        mdiFilePdf,
        mdiEyeOutline,
        mdiDotsVertical,
      },

      itemListTable,
      tableColumns,
      options,

      totalItemListTable,
      loading,

      // parentFilter,
      // itemTotalLocal,
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
