// eslint-disable-next-line no-unused-vars, object-curly-newline
import { ref } from '@vue/composition-api'

export default function useItemList() {
  const itemListTable = ref([])

  const tableColumns = [
    { text: '#', value: 'number', width: '5%' },
    { text: 'TITLE', value: 'displayName', width: '50%' },
    { text: 'IMAGE URL', value: 'slideImageUrl', width: '40%' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
      width: '5%',
    },
  ]

  const options = ref({
    q: '',
    sortBy: ['sort'],
    sortDesc: [false],
  })

  // onInit
  const init = () => {}

  // onDestroy
  const destroy = () => {}

  return {
    itemListTable,
    tableColumns,

    options,

    init,
    destroy,
  }
}
