import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-tab-overview"},[_c(VCard,[_c(VCardTitle,[_vm._v(" Search & Filter ")]),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.items,"options":_vm.options,"loading":_vm.loading,"disable-sort":"","disable-pagination":"","hide-default-footer":"","search":_vm.options.q},on:{"update:search":function($event){return _vm.$set(_vm.options, "q", $event)}},scopedSlots:_vm._u([{key:"item.displayName",fn:function(ref){
var item = ref.item;
return [(item.displayName)?_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'item-view', params : { id : item.id } }}},[_vm._v(" "+_vm._s(item.displayName)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.slideImageUrl",fn:function(ref){
var item = ref.item;
return [(item.slideImageUrl)?_c('a',{staticClass:"text--break",attrs:{"href":item.slideImageUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm._f("textEllipsis")(item.slideImageUrl,120))+" ")]):_c('span',{staticClass:"text--secondary"},[_vm._v("-")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{ name:'item-view', params:{ id: item.id } }}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Item")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }