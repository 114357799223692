<template>
  <div class="user-tab-overview">
    <!-- activity timeline -->
    <v-card>
      <v-card-title>
        Details
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-list
          two-line
          multi-line
        >
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('ID') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                {{ data.id }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('Campaign Name') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                {{ data.campaignName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('Image URL') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                <a
                  v-if="data.thumbUrl"
                  :href="data.thumbUrl"
                  target="_blank"
                >
                  {{ data.thumbUrl }}
                </a>
                <span
                  v-else
                  class="text--secondary"
                >-</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiFilePdf } from '@mdi/js'
import { useUtils } from '@/@core/libs/i18n'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useUtils()

    return {
      t,
      icons: {
        mdiDotsVertical,
        mdiFilePdf,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
