<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <campaign-bio-panel
          v-if="campaign"
          :data="campaign"
          :handle-update="updateData"
        ></campaign-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="campaignTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :append="tab.append"
            :to="tab.to"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="campaignTab"
          class="mt-5 pa-1"
        >
          <v-tab-item
            v-if="campaign"
            value="overview"
          >
            <campaign-tab-overview :data="campaign"></campaign-tab-overview>
          </v-tab-item>
          <v-tab-item
            v-if="campaign"
            value="products"
          >
            <campaign-tab-items
              :campaign-id="campaign.id"
              :items="campaign.products"
            ></campaign-tab-items>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { onUnmounted, ref, provide } from '@vue/composition-api'
import { mdiAccountOutline, mdiFormatListBulleted } from '@mdi/js'
import { useRouter } from '@core/utils'

import useCampaignView from './useCampaignView'
import CampaignBioPanel from './campaign-bio-panel/CampaignBioPanel.vue'
import CampaignTabItems from './campaign-tab-items/CampaignTabItems.vue'
import CampaignTabOverview from './campaign-tab-overview/CampaignTabOverview.vue'

export default {
  components: {
    CampaignBioPanel,
    CampaignTabItems,
    CampaignTabOverview,
  },
  setup() {
    const campaignTab = ref(null)
    const { route } = useRouter()

    const {
      campaign,
      loading,
      updating,

      errorMessages,

      init,
      destroy,

      updateData,
    } = useCampaignView(route.value.params.id)

    // Init
    init()

    // Destroy
    onUnmounted(() => {
      destroy()
    })

    const tabs = [
      {
        icon: mdiAccountOutline,
        title: 'Overview',
        append: true,
        to: '#overview',
      },
      {
        icon: mdiFormatListBulleted,
        title: 'Products',
        append: true,
        to: '#products',
      },
    ]

    return {
      tabs,
      campaignTab,

      campaign,
      loading,
      updating,
      updateData,

      errorMessages,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
